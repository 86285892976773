<template>
  <base-section
    id="customer-reviews"
    class="grey lighten-5"
    space="64"
  >
    <base-section-heading
      icon="mdi-comment"
      space="0"
      title="Developer's Reviews"
    />

    <v-carousel
      class="mt-n12"
      height="200"
      light
      continuous="true"
      cycle="true"
      hide-delimiters="false"
      interval="5000"
      show-arrows-on-hover="true"
    >
      <v-carousel-item
        v-for="(customer, i) in customers"
        :key="i"
      >
        <v-container class="fill-height">
          <v-responsive
            class="mx-auto shrink text-center"
            max-width="90%"
            width="700"
          >
            <base-body
              :text="customer.review"
              tag="em"
              class="mb-6 d-block"
            />
            <base-title
              :title="customer.name"
              space="0"
            />
          </v-responsive>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCustomerReviews',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      customers: [
        {
          review: '"Best add-on ever. I loved the fact that we can add unlimited number of rules and very easy to use. Love to see it on Safari extension as well. It would be of great help to many tech savvys!"',
          name: 'VijayaDeepti Mummaneni',
        },
        {
          review: '"Easy to use and allows for an unlimited number of rules!"',
          name: 'Louis Delma Jr.',
        },
        {
          review: '"A must have extension for a front end developer!"',
          name: 'Michael Yoon',
        },
        {
          review: '"I Love the extension, exactly what I was looking for. its really very much help full for front end developer"',
          name: 'khushi khatri',
        },
        {
          review: '"Very much help full  extension!   Keep it up, it`s awesome :)"',
          name: 'Soniya Iyer',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #customer-reviews
    .v-carousel__controls
      background: transparent

    .base-body
      line-height: 2
</style>
